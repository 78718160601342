import React, { Dispatch, SetStateAction, useState } from 'react'
import { graphql } from 'gatsby'
import SEO from '../../../components/SEO'
import Header from '../../../components/Header/Partners'
import Footer from '../../../components/Footer/Partners'
import Hero from '../../../components/Hero/HeroBgImage'
import Accordion from '../../../components/Accordion'
import StepsCards from '../../../components/Cards/StepsCards'
import Text from '../../../components/Text'
import {
  ContentfulAsset,
  ContentfulCard,
  ContentfulCards,
  ContentfulFeatureContent,
  ContentfulList,
  ContentfulMedia,
  ContentfulPage,
  ContentfulText,
} from '../../../../types/graphql-types'
import {
  AccordionCarrot,
  AccordionStyled,
  AccordionTitle,
  CollapseBody,
} from '../../../styles/support-styles'
import {
  AdditionalSituations,
  CallUs,
  FAQSection,
  FeaturedContent,
  HeroWrapper,
  Intro,
  StepsSection,
} from '../../../styles/cambia-styles'
import LayoutPartners from '../../../components/Layout/Partners/LayoutPartners'
import { IGatsbyImageData } from 'gatsby-plugin-image'

interface Props {
  data: {
    contentfulPage: ContentfulPage
  }
}

interface StepCards extends ContentfulAsset {
  readonly stepCards: IGatsbyImageData
}

export const query = graphql`
  query RegenceId {
    contentfulPage(externalName: { eq: "Cambia Regence ID" }) {
      ...cambiaPage
    }
  }
`

const RegenceIdPage = ({ data }: Props): JSX.Element => {
  // Get FAQ list
  const getFaqList = (data.contentfulPage.references as ContentfulList[]).find(
    faqs => faqs.externalName === 'FAQs'
  )

  // Get Open FAQ radio button
  const openFaq = getFaqList?.openFaq

  // Get first FAQ
  const getFaq = (getFaqList?.items as ContentfulText[]).find(
    faq => faq.externalName === 'Faq'
  )

  // Get first FAQ ID
  const indexFaq = getFaq?.id

  {
    /* If Open FAQ is set to true then indexFaq will be 
    first FAQ Id otherwise it will be set to empty string.*/
  }
  const [activeEventKey, setActiveEventKey] = useState(
    openFaq === true ? `${indexFaq}` : ''
  )

  return (
    <LayoutPartners>
      <SEO
        title={data.contentfulPage?.seoTitle as string}
        description={data.contentfulPage?.seoDescription as string}
        noIndex={data.contentfulPage?.noindex as boolean}
      />
      <Header
        classes="border-t-12 md:border-t-45 border-regence-blue"
        logo={(data.contentfulPage.references as ContentfulMedia[]).map(
          logo => {
            if (logo?.externalName === 'Brand Logo') {
              return (
                <img
                  key={logo.id}
                  className="h-7 sm:h-10"
                  src={logo.media?.file?.url as string}
                  alt={logo.altText as string}
                />
              )
            }
          }
        )}
      />
      <main id="main">
        <HeroWrapper>
          <Hero data={data} />
        </HeroWrapper>
        {data.contentfulPage?.references?.map(content => {
          const featureContent = content as ContentfulFeatureContent
          const list = content as ContentfulList
          const text = content as ContentfulText
          // Intro Section
          if (text.externalName === 'Intro') {
            return (
              <Intro key={text.id} className="content-section-full">
                <div className="container">
                  <Text rich={text} />
                </div>
              </Intro>
            )
          }
          // Step Cards
          if (
            featureContent.externalName === 'Partner Step Cards Feature Content'
          ) {
            return (
              <StepsSection key={featureContent.id} className="content-section">
                {featureContent.references?.map(feature => {
                  const cards = feature as ContentfulCards
                  const text = feature as ContentfulText
                  if (text.externalName === 'Feature Content Copy') {
                    return (
                      <div key={text.id} className="steps-section-intro">
                        <Text rich={text} />
                      </div>
                    )
                  }

                  if (cards.externalName === 'Steps Cards') {
                    return (
                      <ul key={cards.id} className="steps">
                        {(cards.cards as ContentfulCard[]).map(card => {
                          return (
                            <li key={card.id}>
                              <StepsCards
                                title={card.title as string}
                                numberColor="bg-regence-blue"
                                category={card.category as string}
                                headline={card?.headline}
                                headlineRichText={card?.headlineRichText}
                                altText={
                                  card.featuredImage?.description as string
                                }
                                featuredImage={
                                  (card.featuredImage as StepCards).stepCards
                                }
                              />
                            </li>
                          )
                        })}
                      </ul>
                    )
                  }
                })}
              </StepsSection>
            )
          }
          // Cambia Fall Detection Feature Content
          if (
            featureContent.externalName ===
            'Cambia Fall Detection Feature Content'
          ) {
            return (
              <FeaturedContent
                key={featureContent.id}
                className="content-section-py"
              >
                <div className="mb-8 grid content-center lg:w-3/4">
                  {(featureContent.references as ContentfulText[]).map(
                    feature => {
                      if (feature.externalName === 'Feature Content Copy') {
                        return <Text key={feature.id} rich={feature} />
                      }
                    }
                  )}
                </div>
                {(featureContent.references as ContentfulMedia[]).map(
                  feature => {
                    if (feature.externalName === 'Feature Content Image') {
                      return (
                        <img
                          key={feature.id}
                          alt={feature.altText as string}
                          src={feature.media?.file?.url as string}
                        />
                      )
                    }
                  }
                )}
              </FeaturedContent>
            )
          }
          // Call Us Section
          if (text.externalName === 'Call Us') {
            return (
              <CallUs key={text.id}>
                <div className="container">
                  <Text rich={text} />
                </div>
              </CallUs>
            )
          }
          // FAQ Section
          if (list.externalName === 'FAQs') {
            return (
              <FAQSection key={list.id} className="content-section-py">
                <h2 className="mb-8 text-center">{list.name}</h2>
                {(list.items as ContentfulText[]).map((item, index) => {
                  return (
                    <Accordion
                      activeEventKey={activeEventKey}
                      onToggle={
                        setActiveEventKey as unknown as Dispatch<
                          SetStateAction<string | undefined>
                        >
                      }
                      key={index}
                    >
                      <AccordionStyled>
                        <Accordion.Toggle
                          onClick={(
                            event: React.MouseEvent<
                              HTMLButtonElement,
                              MouseEvent
                            >
                          ) => void event}
                          eventKey={item?.id}
                          data-analytics-faq={item?.name}
                          aria-controls={`${item?.id}-controls`}
                          aria-expanded={
                            activeEventKey === item?.id ? true : false
                          }
                          id={item?.id}
                          className="flex w-full items-center justify-between p-4"
                        >
                          <AccordionTitle>
                            <div>
                              <p>{item?.name}</p>
                            </div>
                          </AccordionTitle>
                          <AccordionCarrot
                            className={`icon-angle-down`}
                          ></AccordionCarrot>
                        </Accordion.Toggle>
                        <Accordion.Collapse
                          eventKey={item?.id}
                          role="region"
                          aria-labelledby={item?.id}
                          id={`${item?.id}-controls`}
                        >
                          <CollapseBody
                            className="px-4"
                            dangerouslySetInnerHTML={{
                              __html: item.longSimpleText?.childMarkdownRemark
                                ?.html as string,
                            }}
                          ></CollapseBody>
                        </Accordion.Collapse>
                      </AccordionStyled>
                    </Accordion>
                  )
                })}
              </FAQSection>
            )
          }
          // Additional Situations Featured Content
          if (
            featureContent.externalName ===
            'Additional Situations Featured Content'
          ) {
            return (
              <AdditionalSituations
                key={featureContent.id}
                className="content-section-full"
              >
                <div className="container">
                  {(featureContent.references as ContentfulText[]).map(
                    feature => {
                      if (feature.externalName === 'Feature Content Copy') {
                        return (
                          <Text key={feature.id} long={feature} tag="div" />
                        )
                      }
                    }
                  )}
                </div>
              </AdditionalSituations>
            )
          }
          // Page Disclaimer
          if (text.externalName === 'Page Disclaimer') {
            return (
              <section key={text.id} className="content-section-py">
                <div className="container">
                  <Text long={text} tag="div" />
                </div>
              </section>
            )
          }
        })}
      </main>
      <Footer color="bg-regence-blue" data={data} />
    </LayoutPartners>
  )
}

export default RegenceIdPage
